
// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) { 
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}

// todo this way
const translations = {
    meeting: {
        name: 'Meeting name'
    }
};

function getValueFromDottedString(obj, str) {
    const split = str.split(".");
    for (var i = 0; i < split.length; i++) {
        obj = obj[split[i]];
        if (obj == null) {
            return null
        }
    }

    return obj;
}


const translate = (key, default_translation, values = null) => {
    let value;
    if(translations[key]) {
        value = translations[key]
    }
    else {
        value = default_translation
    }

    if(values) {
        try {
            return value.format(values)
        }
        catch(e) {
            console.error(e)
            console.error('Translation error: '+value)
        }
    }

    return value
}

const __ = translate

export default __