import React, { Fragment } from "react";
import { notification, Layout } from "antd";
import { CircularProgress } from "@material-ui/core";
import {
  LocalVideoView,
  MainVideoView,
  SmallVideoView,
  VideoGrid,
} from "./videoview";
import { Client, LocalStream, RemoteStream } from "ion-sdk-js";
import "../styles/css/conference.scss";
import __ from "./util/translate";
import { IconButton, DownloadIcon, RecordIcon } from "./icons/icons";
const { Header, Content, Footer, Sider } = Layout;

class Conference extends React.Component {
  constructor() {
    super();
    this.state = {
      streams: [],
      localStream: null,
      localScreen: null,
      audioMuted: false,
      videoMuted: false,
      recording: false,
    };
    this.mediaRecorder = null;
    this.recordedBlobs = null;
  }

  startRecording = () => {
    this.recordedBlobs = [];
    let options = { mimeType: "video/webm;codecs=vp9,opus" };
    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
      console.error(`${options.mimeType} is not supported`);
      options = { mimeType: "video/webm;codecs=vp8,opus" };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.error(`${options.mimeType} is not supported`);
        options = { mimeType: "video/webm" };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.error(`${options.mimeType} is not supported`);
          options = { mimeType: "" };
        }
      }
    }

    try {
      this.mediaRecorder = new MediaRecorder(this.state.localStream, options);
      this.setState({
        recording: true
      })
    } catch (e) {
      console.error("Exception while creating MediaRecorder:", e);
      errorMsgElement.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(
        e
      )}`;
      return;
    }

    console.log(
      "Created MediaRecorder",
      this.mediaRecorder,
      "with options",
      options
    );
    //recordButton.textContent = "Stop Recording";
    //playButton.disabled = true;
    //downloadButton.disabled = true;
    this.mediaRecorder.onstop = (event) => {
      console.log("Recorder stopped: ", event);
      console.log("Recorded Blobs: ", this.recordedBlobs);
    };
    this.mediaRecorder.ondataavailable = this.handleDataAvailable;
    this.mediaRecorder.start();
    console.log("MediaRecorder started", this.mediaRecorder);
  };

  stopRecording = () => {
    this.mediaRecorder.stop();

    this.setState({
      recording: false
    })
  };

  handleDataAvailable = (event) => {
    console.log("handleDataAvailable", event);
    if (event.data && event.data.size > 0) {
      this.recordedBlobs.push(event.data);
    }
  };

  downloadRecording = () => {
    const blob = new Blob(this.recordedBlobs, { type: "video/webm" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "test.webm";
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  };

  componentDidMount = () => {
    const { client } = this.props;
    client.on("stream-add", this._handleAddStream);
    client.on("stream-remove", this._handleRemoveStream);
  };

  componentWillUnmount = () => {
    const { client } = this.props;
    client.off("stream-add", this._handleAddStream);
    client.off("stream-remove", this._handleRemoveStream);
  };

  cleanUp = async () => {
    let { localStream, localScreen, streams } = this.state;
    await this.setState({ localStream: null, localScreen: null, streams: [] });

    streams.map(async (item) => {
      await item.stream.unsubscribe();
    });

    await this._unpublish(localStream);
  };

  _notification = (message, description) => {
    notification.info({
      message: message,
      description: description,
      //placement: "bottomRight"
    });
  };

  _unpublish = async (stream) => {
    const { client } = this.props;
    if (stream) {
      await this._stopMediaStream(stream);
      await stream.unpublish();
    }
  };

  muteMediaTrack = (type, enabled) => {
    let { localStream } = this.state;
    if (!localStream) {
      return;
    }
    if (enabled) {
      localStream.unmute(type);
    } else {
      localStream.mute(type);
    }

    if (type === "audio") {
      this.setState({ audioMuted: !enabled });
    } else if (type === "video") {
      this.setState({ videoMuted: !enabled });
    }
  };

  handleLocalStream = async (enabled) => {
    let { localStream } = this.state;
    const { client, settings } = this.props;
    console.log(settings);
    try {
      if (enabled) {
        localStream = await LocalStream.getUserMedia({
          codec: settings.codec.toUpperCase(),
          resolution: settings.resolution,
          bandwidth: settings.bandwidth,
          audio: true,
          video: true,
        });
        this.props.userMediaFoundCallback();
        await client.publish(localStream);
      } else {
        if (localStream) {
          this._unpublish(localStream);
          localStream = null;
        }
      }
      console.log("local stream", localStream.getTracks());
      this.setState({ localStream });
    } catch (e) {
      console.log("handleLocalStream error => " + e);
      // this._notification("publish/unpublish failed!", e);
    }

    //Check audio only conference
    this.muteMediaTrack("video", this.props.localVideoEnabled);
  };

  handleScreenSharing = async (enabled) => {
    let { localScreen } = this.state;
    const { client, settings } = this.props;
    if (enabled) {
      localScreen = await LocalStream.getDisplayMedia({
        codec: settings.codec.toUpperCase(),
        resolution: settings.resolution,
        bandwidth: settings.bandwidth,
      });
      await client.publish(localScreen);
      let track = localScreen.getVideoTracks()[0];
      if (track) {
        track.addEventListener("ended", () => {
          this.handleScreenSharing(false);
        });
      }
    } else {
      if (localScreen) {
        this._unpublish(localScreen);
        localScreen = null;
      }
    }
    this.setState({ localScreen });
  };

  _stopMediaStream = async (stream) => {
    let tracks = stream.getTracks();
    for (let i = 0, len = tracks.length; i < len; i++) {
      await tracks[i].stop();
    }
  };

  _handleAddStream = async (mid, info) => {
    const { client } = this.props;
    let streams = this.state.streams;
    let stream = await client.subscribe(mid);
    stream.info = info;
    console.log(mid, info, stream);
    streams.push({ mid: stream.mid, stream, sid: mid });
    this.setState({ streams });
  };

  _handleRemoveStream = async (stream) => {
    let streams = this.state.streams;
    streams = streams.filter((item) => item.sid !== stream.mid);
    this.setState({ streams });
  };

  _onChangeVideoPosition = (data) => {
    let id = data.id;
    let index = data.index;
    console.log("_onChangeVideoPosition id:" + id + "  index:" + index);

    if (index == 0) {
      return;
    }

    const streams = this.state.streams;
    let first = 0;
    let big = 0;
    for (let i = 0; i < streams.length; i++) {
      let item = streams[i];
      if (item.mid == id) {
        big = i;
        break;
      }
    }

    let c = streams[first];
    streams[first] = streams[big];
    streams[big] = c;

    this.setState({ streams: streams });
  };

  render = () => {
    const { client, vidFit, inlineMode, minimizeMode } = this.props;
    const {
      streams,
      localStream,
      localScreen,
      audioMuted,
      videoMuted,
    } = this.state;
    const id = client.uid;

    const additional_toolbar = (
      <Fragment>
        {this.state.recording ? 
            <IconButton onClick={this.stopRecording}>
              <RecordIcon disabled={true} />
            </IconButton> :
            <IconButton onClick={this.startRecording}>
              <RecordIcon />
            </IconButton>}
        {!this.state.recording && this.recordedBlobs !== null &&
            <IconButton onClick={this.downloadRecording}>
              <DownloadIcon />
            </IconButton>
        }
        {/*!this.state.recording && this.recordedBlobs !== null && (<Fragment>{this.recordedBlobs.map((blob) => 
          <button onClick={this.downloadRecording}> Download ({blob.size / 1024.0} MB)</button>
        )}</Fragment>)*/}
      </Fragment>
    );

    return (
      <Layout className="conference-layout">
        <Content style={{ flex: 1 }}>
          <Layout className="conference-main">
            <Content style={{ flex: 1 }}>
              {streams.length === 0 && (
                <div className="conference-layout-waiting loading">
                  <CircularProgress />
                  <div className="loading-message">
                    {__(
                      "conference.wait_message",
                      "Warte auf andere Teilnehmer"
                    )}
                  </div>
                </div>
              )}
              {streams.map((item, index) => {
                return index == 0 ? (
                  <MainVideoView
                    key={item.mid}
                    id={item.mid}
                    stream={item.stream}
                    vidFit={vidFit}
                  />
                ) : (
                  ""
                );
              })}
              {this.props.side_buttons}
              <VideoGrid
                streams={streams}
                collapsed={this.props.collapsed}
                onChangeVideoPosition={this._onChangeVideoPosition}
              />
            </Content>
            <Footer>
              {this.props.toolbar} {additional_toolbar}
            </Footer>
          </Layout>
        </Content>
        <Sider
          width={320}
          className="conference-side"
          collapsedWidth={0}
          trigger={null}
          collapsible
          collapsed={this.props.collapsed || minimizeMode}
        >
          <div className="local-video-container">
            {localStream && (
              <div className="conference-local-video-layout">
                <LocalVideoView
                  id={id + "-video"}
                  label={__("conference.local_video", "Ich")}
                  client={client}
                  stream={localStream}
                  audioMuted={audioMuted}
                  videoMuted={videoMuted}
                  videoType="localVideo"
                />
              </div>
            )}
            {localScreen && (
              <div className="conference-local-screen-layout">
                <LocalVideoView
                  id={id + "-screen"}
                  label={__("conference.screen_sharing", "Mein Bildschirm")}
                  client={client}
                  stream={localScreen}
                  audioMuted={false}
                  videoMuted={false}
                  videoType="localScreen"
                />
              </div>
            )}
          </div>
          <div className="chat-container">{this.props.chat}</div>
        </Sider>
      </Layout>
    );
  };
}

export default Conference;
