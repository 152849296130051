import React from "react";
import { VideoIcon, MicrophoneIcon } from "../icons/icons";

export default function VideoStatusWithName(props) {
  return (
    <div className={`video-name ${props.type}-video-name`}>
      <div className="video-name-inner">
        <VideoIcon disabled={!props.videoEnabled} />
        <MicrophoneIcon disabled={!props.audioEnabled} />
        <span className="label">{props.label}</span>
      </div>
    </div>
  );
}
