import React from "react";
import { Modal, Button, Select, Tooltip, Switch } from "antd";
import SoundMeter from "./soundmeter";
import PropTypes from "prop-types";
import "./style.scss";
import __ from "../util/translate";
import { IconButton, MenuIcon } from "../icons/icons";

const Option = Select.Option;

const closeMediaStream = function (stream) {
  if (!stream) {
    return;
  }
  if (
    MediaStreamTrack &&
    MediaStreamTrack.prototype &&
    MediaStreamTrack.prototype.stop
  ) {
    var tracks, i, len;

    if (stream.getTracks) {
      tracks = stream.getTracks();
      for (i = 0, len = tracks.length; i < len; i += 1) {
        tracks[i].stop();
      }
    } else {
      tracks = stream.getAudioTracks();
      for (i = 0, len = tracks.length; i < len; i += 1) {
        tracks[i].stop();
      }

      tracks = stream.getVideoTracks();
      for (i = 0, len = tracks.length; i < len; i += 1) {
        tracks[i].stop();
      }
    }
    // Deprecated by the spec, but still in use.
  } else if (typeof stream.stop === "function") {
    console.log("closeMediaStream() | calling stop() on the MediaStream");
    stream.stop();
  }
};

// Attach a media stream to an element.
const attachMediaStream = function (element, stream) {
  element.srcObject = stream;
};

export default class MediaSettings extends React.Component {
  constructor(props) {
    super(props);
    let settings = props.settings;
    this.state = {
      visible: false,
      videoDevices: [],
      audioDevices: [],
      audioOutputDevices: [],
      resolution: settings.resolution,
      bandwidth: settings.bandwidth,
      selectedAudioDevice: settings.selectedAudioDevice,
      selectedVideoDevice: settings.selectedVideoDevice,
      codec: settings.codec,
      isDevMode: settings.isDevMode,
      simpleUserMode: settings.simpleUserMode,
    };

    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      window.audioContext = new AudioContext();
    } catch (e) {
      console.log("Web Audio API not supported.");
    }
  }

  updateInputDevices = () => {
    return new Promise((pResolve, pReject) => {
      let videoDevices = [];
      let audioDevices = [];
      let audioOutputDevices = [];
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          for (let device of devices) {
            if (device.kind === "videoinput") {
              videoDevices.push(device);
            } else if (device.kind === "audioinput") {
              audioDevices.push(device);
            } else if (device.kind === "audiooutput") {
              audioOutputDevices.push(device);
            }
          }
        })
        .then(() => {
          let data = { videoDevices, audioDevices, audioOutputDevices };
          pResolve(data);
        });
    });
  };

  setDevicesToState = (data) => {
    if (this.state.selectedAudioDevice === "" && data.audioDevices.length > 0) {
      this.state.selectedAudioDevice = data.audioDevices[0].deviceId;
    }
    if (this.state.selectedVideoDevice === "" && data.videoDevices.length > 0) {
      this.state.selectedVideoDevice = data.videoDevices[0].deviceId;
    }

    this.setState({
      videoDevices: data.videoDevices,
      audioDevices: data.audioDevices,
      audioOutputDevices: data.audioOutputDevices,
    });

    this.state.audioDevices.map((device, index) => {
      if (this.state.selectedAudioDevice == device.deviceId) {
        console.log("Selected audioDevice::" + JSON.stringify(device));
      }
    });
    this.state.videoDevices.map((device, index) => {
      if (this.state.selectedVideoDevice == device.deviceId) {
        console.log("Selected videoDevice::" + JSON.stringify(device));
      }
    });
  };

  componentDidMount() {
    this.updateInputDevices().then(this.setDevicesToState);
  }

  soundMeterProcess = () => {
    var val = window.soundMeter.instant.toFixed(2) * 348 + 1;
    this.setState({ audioLevel: val });
    if (this.state.visible) setTimeout(this.soundMeterProcess, 100);
  };

  startPreview = () => {
    if (window.stream) {
      closeMediaStream(window.stream);
    }
    let videoElement = this.refs["previewVideo"];
    let audioSource = this.state.selectedAudioDevice;
    let videoSource = this.state.selectedVideoDevice;
    this.soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
    let soundMeterProcess = this.soundMeterProcess;
    let constraints = {
      audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: { deviceId: videoSource ? { exact: videoSource } : undefined },
    };
    let t = this;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        window.stream = stream; // make stream available to console
        //videoElement.srcObject = stream;
        attachMediaStream(videoElement, stream);
        soundMeter.connectToSource(stream);
        setTimeout(soundMeterProcess, 100);

        //t.updateInputDevices();
        t.updateInputDevices().then(t.setDevicesToState);
        //t.forceUpdate();

        // Refresh button list in case labels have become available
        return navigator.mediaDevices.enumerateDevices();
      })

      .then((devces) => {})
      .catch((erro) => {});
  };

  stopPreview = () => {
    if (window.stream) {
      closeMediaStream(window.stream);
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
    setTimeout(this.startPreview, 100);
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    this.stopPreview();
    if (this.props.onMediaSettingsChanged !== undefined) {
      this.props.onMediaSettingsChanged(
        this.state.simpleUserMode,
        this.state.selectedAudioDevice,
        this.state.selectedVideoDevice,
        this.state.resolution,
        this.state.bandwidth,
        this.state.codec,
        this.state.isDevMode
      );
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
    this.stopPreview();
  };

  handleAudioDeviceChange = (e) => {
    this.setState({ selectedAudioDevice: e });
    setTimeout(this.startPreview, 100);
  };

  handleVideoDeviceChange = (e) => {
    this.setState({ selectedVideoDevice: e });
    setTimeout(this.startPreview, 100);
  };

  handleResolutionChange = (e) => {
    this.setState({ resolution: e });
  };

  handleVideoCodeChange = (e) => {
    this.setState({ codec: e });
  };

  handleBandWidthChange = (e) => {
    this.setState({ bandwidth: e });
  };

  handleDevChange = (checked) => {
    this.setState({
      isDevMode: checked,
    });
  };

  handleSimpleUserModeChange = (checked) => {
    this.setState({
      simpleUserMode: checked,
    });
  };

  render() {
    return (
      <div>
        {
          <Tooltip title={__("settings.title", "Einstellungen")}>
            <IconButton type="link" onClick={this.showModal}>
              <MenuIcon />
            </IconButton>
          </Tooltip>
        }
        <Modal
          title={__("settings.title", "Einstellungen")}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={__("ok", "OK")}
          cancelText={__("cancel", "Abbrechen")}
        >
          <div className="settings-item" style={{ display: "none" }}>
            <span className="settings-item-left">
              {__("settings.mode", "DevMode")}
            </span>
            <div className="settings-item-right">
              <Switch
                checked={this.state.isDevMode}
                onChange={this.handleDevChange}
              />
            </div>
          </div>
          <div className="settings-item">
            <span className="settings-item-left">
              {__("settings.simpleUserMode", "Einfacher Modus")}
            </span>
            <div className="settings-item-right">
              <Switch
                checked={this.state.simpleUserMode}
                onChange={this.handleSimpleUserModeChange}
              />
            </div>
          </div>
          <div className="settings-item">
            <span className="settings-item-left">
              {__("settings.microphone", "Mikrofon")}
            </span>
            <div className="settings-item-right">
              <Select
                value={this.state.selectedAudioDevice}
                onChange={this.handleAudioDeviceChange}
              >
                {this.state.audioDevices.map((device, index) => {
                  return (
                    <Option value={device.deviceId} key={device.deviceId}>
                      {device.label}
                    </Option>
                  );
                })}
              </Select>
              <div
                className="settings-audiolevel"
                ref="progressbar"
                style={{
                  width: this.state.audioLevel + "px",
                  height: "10px",
                  backgroundColor: "#8dc63f",
                  marginTop: "20px",
                }}
              ></div>
            </div>
          </div>
          <div className="settings-item">
            <span className="settings-item-left">
              {__("settings.camera", "Kamera")}
            </span>
            <div className="settings-item-right">
              <Select
                value={this.state.selectedVideoDevice}
                onChange={this.handleVideoDeviceChange}
              >
                {this.state.videoDevices.map((device, index) => {
                  return (
                    <Option value={device.deviceId} key={device.deviceId}>
                      {device.label}
                    </Option>
                  );
                })}
              </Select>
              <div className="settings-video-container">
                <video
                  id="previewVideo"
                  ref="previewVideo"
                  autoPlay
                  playsInline
                  muted={true}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                ></video>
              </div>
            </div>
          </div>
          <div className="settings-item">
            <span className="settings-item-left">
              {__("settings.quality", "Qualität")}
            </span>
            <div className="settings-item-right">
              <Select
                value={this.state.resolution}
                onChange={this.handleResolutionChange}
              >
                <Option value="qvga">QVGA(320x180)</Option>
                <Option value="vga">VGA(640x360)</Option>
                <Option value="shd">SHD(960x540)</Option>
                <Option value="hd">HD(1280x720)</Option>
              </Select>
            </div>
          </div>
          <div className="settings-item">
            <span className="settings-item-left">
              {__("settings.video_codex", "Video-Codec")}
            </span>
            <div className="settings-item-right">
              <Select
                value={this.state.codec}
                onChange={this.handleVideoCodeChange}
              >
                <Option value="h264">H264</Option>
                <Option value="vp8">VP8</Option>
                <Option value="vp9">VP9</Option>
              </Select>
            </div>
          </div>
          <div className="settings-item">
            <span className="settings-item-left">
              {__("settings.bandwidth", "Bandbreite")}
            </span>
            <div className="settings-item-right">
              <Select
                value={this.state.bandwidth}
                onChange={this.handleBandWidthChange}
              >
                <Option value="256">Low(256kbps)</Option>
                <Option value="512">Medium(512kbps)</Option>
                <Option value="1024">High(1Mbps)</Option>
                <Option value="4096">Lan(4Mbps)</Option>
              </Select>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

MediaSettings.propTypes = {
  onMediaSettingsChanged: PropTypes.func,
};
