import React from "react";
import { Button } from "antd";

//export MicrophoneIcon from "mdi-react/MicrophoneIcon";
//export {MicrophoneOffIcon} from "mdi-react/MicrophoneOffIcon";
export { ChatIcon } from "mdi-react/ChatIcon";
//export {HangupIcon} from "mdi-react/PhoneHangupIcon";
export { TelevisionIcon } from "mdi-react/TelevisionIcon";
export { TelevisionOffIcon } from "mdi-react/TelevisionOffIcon";
//import VideoIcon from "mdi-react/VideoIcon";
//port {VideocamOffIcon} from "mdi-react/VideocamOffIcon";

//const ttf = require("./fonts/Linearicons.ttf");
import "./icons.css";

export function PhoneIcon(props) {
  return <Icon icon="phone-handset" {...props} />;
}
export function AudioIcon(props) {
  return <Icon icon="volume-high" {...props} />;
}
export function VideoIcon(props) {
  const icon = "camera";
  return <Icon icon={icon} {...props} />;
}

export function UploadIcon(props) {
  return <Icon icon="file-add" {...props} />;
}
export function SendIcon(props) {
  return <Icon icon="chevron-right" {...props} />;
}

export function MicrophoneIcon(props) {
  //const icon = props.disabled ? 'mic-disabled' : 'mic';
  const icon = "mic";
  return <Icon icon={icon} {...props} />;
}
export function HangupIcon(props) {
  const icon = SVGIcon("hangup", props);
  return icon;
  return (
    <IconButton background="danger" {...props}>
      <SVGIcon icon="phone-handset" {...props.icon} />
    </IconButton>
  );
}

function SVGIcon(cls, props) {
  return <Icon style={{ border: "3px solid #fc0" }} svgicon={cls} {...props} />;
}

function GenericIcon(cls, props) {
  return <Icon icon={cls} {...props} />;
  /*
    return function (props) {
        return <Icon icon={cls} {...props} />;
    }
    */
}

export function HeadsetIcon(props) {
  return GenericIcon("headset", props);
}

export function RecordIcon(props) {
  return GenericIcon("record", props);
}

export function DownloadIcon(props) {
  return <Icon icon="download2" {...props} />;
}


export function ShareOnIcon(props) {
  return SVGIcon("share-on", props);
}
export function ShareOffIcon(props) {
  return SVGIcon("share-off", props);
}
export function StretchIcon(props) {
  return SVGIcon("enlarge", props);
}
export function UnstretchIcon(props) {
  return SVGIcon("minimize", props);
}

export function PiPOnIcon(props) {
  return SVGIcon("pip-on", props);
}
export function PiPOffIcon(props) {
  return SVGIcon("pip-off", props);
}
export function CloseIcon(props) {
  return <Icon icon="cross" {...props} />;
}
export function MenuIcon(props) {
  return <Icon icon="menu" {...props} />;
}

export function IconButton(props) {
  let cls = "icon-button-container";
  if (props.className) {
    cls += " " + props.className;
  }
  if (props.background) {
    cls += " background-" + props.background;
  }
  return <span {...props} className={cls} />;
}
export function Icon(props) {
  let cls;
  //console.log(props.cls + ": " + props.enabled);
  if (props.disabled !== undefined) {
    cls = props.disabled ? " icon-disabled" : "";
  } else if (props.enabled !== undefined) {
    cls = !props.enabled ? " icon-disabled" : "";
  }

  if (props.svgicon !== undefined) {
    //console.log('props', props.svgicon)
    return (
      <span
        onClick={props.onClick}
        className={`icon-button icon-danger ${cls}`}
      >
        <i className={`svgicon svgicon-${props.svgicon}`}></i>
      </span>
    );
  }

  if (props.background === "danger") {
    return (
      <span
        onClick={props.onClick}
        className={`icon-button icon-danger ${cls}`}
      >
        <i className={`lnr lnr-${props.icon}`}></i>
      </span>
    );
  }
  return (
    <span onClick={props.onClick} className={`icon-button ${cls}`}>
      <i className={`lnr lnr-${props.icon}`}></i>
    </span>
  );
  /*
    return (
        <Button
            icon={props.icon}
            size="large"
            //shape="circle"
            onClick={props.onClick}
            />
    )
    */
}
