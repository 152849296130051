
/*
const Config = {
    logo: 'https://s2-data.at/wp-content/uploads/2019/09/s2_logo-768x260.png',
    home_link: 'https://s2-data.at',
    theme: 's2data',
    primary: '#666',
    secondary: '#999',
};
*/

/*
const Config = {
    logo: 'https://4myhealth.eu/wp-content/uploads/Logo_Gr%C3%B6%C3%9Fe-f%C3%BCr-Webseite-mod-300x118.png',
    home_link: 'https://4myhealth.eu',
    theme: '4myhealth',
    primary: '#666',
    secondary: '#999',
    web_font: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap',
};
*/

const Config = {
    //logo: 'https://medakte.com/wp-content/uploads/2020/03/medakte_logo-300x69.png',
    logo_icon: 'dset',
    logo_text: 'Videosprechstunde',
    home_link: 'https://medakte.com',
    theme: 'medakte',
    primary: '#e30613',
    secondary: '#df6c74',
    web_font: 'https://fonts.googleapis.com/css2?family=Titillium+Web%3Awght%40200%3B300%3B400&#038;display=swap&#038;ver=5.3.2',
}

export function fromInlineConfig(key, default_value, property = 'videochat') {
    console.log(property, key)
    if(window[property] && window[property][key] !== undefined)
        console.log('value', window[property][key])
        return window[property][key]
    return default_value
}

export default Config;
