import React from "react";
import SmallVideoView from "./SmallVideoView"

export default function VideoGrid(props) {
  return (
    <div className="small-video-list-div">
      <div className="small-video-list">
        {props.streams.map((item, index) => {
          return index > 0 ? (
            <SmallVideoView
              key={index}
              id={item.mid}
              stream={item.stream}
              videoCount={props.streams.length}
              collapsed={props.collapsed}
              index={index}
              onClick={props.onChangeVideoPosition}
            />
          ) : null
        })}
      </div>
    </div>
  );
}
