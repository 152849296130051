import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import "antd/dist/antd.css";
//import "antd/dist/antd.less";
import "../styles/css/antd.less";
//import "../styles/css/styles.scss";
import config, {fromInlineConfig} from "./config";

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
        main: config.primary,
        //light: '#df6c74',
    },
    secondary: {
        main: config.secondary,
    }
  },
});

let embedded_name = 'videochat';
if(window.videochat && window.videochat.id) {
  embedded_name =  window.videochat.id;
}
let inline_mode = true;
if(window.videochat && window.videochat.normalMode) {
  inline_mode = false;
}

let app_el = document.getElementById(embedded_name)
if (app_el === null) {
  app_el = document.createElement('div');
  app_el.id = embedded_name;
  app_el.style.height = '100%';
  document.body.append(app_el);
}

/*
if (inline_mode) {
  app_el.classList.add('inline-mode')
}
*/

ReactDOM.render((
    <ThemeProvider theme={theme}>
        <App 
          inlineMode={inline_mode}
          bottomToolbar={true} 
          //bottomToolbar={fromInlineConfig('bottomToolbar', true)} 
        />
    </ThemeProvider>
), app_el);

/*
// Create new link Element 
var link = document.createElement('link'); 
link.rel = 'stylesheet';  
link.type = 'text/css'; 
link.href = config.stylesheet;  
document.head.appendChild(link);  
*/

// Create new font Element 
if(config.web_font) {
  var font_link = document.createElement('link'); 
  font_link.rel = 'stylesheet';  
  font_link.href = config.web_font;  
  document.head.appendChild(font_link);  
}
/*
if(config.web_font) {
  var font_link = document.createElement('link'); 
  font_link.rel = 'stylesheet';  
  font_link.href = config.web_font;  
  document.head.appendChild(font_link);  
}
*/