import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input, Button, Icon, Tooltip } from "antd";
import SendIcon from "mdi-react/SendIcon";
import UploadIcon from "mdi-react/UploadIcon";
import * as Icons from "../icons/icons";
import __ from "../util/translate";

export default class ChatInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputMessage: "",
    };
  }

  onInputChange = (event) => {
    this.setState({
      inputMessage: event.target.value,
    });
  };

  onBtnSendHandler = (event) => {
    this.sendMessage();
  };

  onFileChange = (file) => {
    window.videochat.callbacks.onDocumentUpload(file);
  };

  onInputKeyUp = (event) => {
    if (event.keyCode == 13) {
      this.sendMessage();
    }
  };

  sendMessage = () => {
    let msg = this.state.inputMessage;

    if (msg.length === 0) {
      return;
    }
    if (msg.replace(/(^\s*)|(\s*$)/g, "").length === 0) {
      return;
    }
    this.props.onSendMessage(msg);
    this.setState({
      inputMessage: "",
    });
  };

  render() {
    return (
      <div className="chat-input">
        {window.videochat &&
        window.videochat.callbacks &&
        window.videochat.callbacks.onDocumentUpload ? (
          <FileInput onChange={this.onFileChange}>
            <Tooltip title={__("chat.upload", "Datei uploaden")}>
              <Icons.UploadIcon />
            </Tooltip>
            {/*
            <Button
              type="secondary"
              style={{ marginLeft: "4px", paddingLeft: 5, paddingRight: 5 }}
            >
              <Icon component={UploadIcon} />
            </Button>
            */}
          </FileInput>
        ) : null}
        <Input
          placeholder={__("chat.placeholder_message", "Nachricht schreiben")}
          onChange={this.onInputChange}
          onPressEnter={this.onInputKeyUp}
          value={this.state.inputMessage}
        />
        <Tooltip title={__("chat.send_message", "Nachricht senden")}>
          <Icons.SendIcon onClick={this.onBtnSendHandler} />
        </Tooltip>
        {/*
        <Button
          type="primary"
          style={{ marginLeft: "4px", paddingLeft: 5, paddingRight: 5 }}
          onClick={this.onBtnSendHandler}
        >
          <Icon component={Icons.SendIcon} />
        </Button>
        */}
      </div>
    );
  }
}

ChatInput.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
};

class FileInput extends React.Component {
  constructor(props) {
    super(props);

    this._handleUpload = this._handleUpload.bind(this);
  }

  _handleUpload(evt) {
    const file = evt.target.files[0];
    this.props.onChange(file);

    // free up the fileInput again
    this.fileInput.value = null;
  }

  render() {
    return (
      <div style={{ display: "flex", ...this.props.style }}>
        <input
          type="file"
          style={{ display: "none" }}
          onChange={this._handleUpload}
          ref={(ele) => (this.fileInput = ele)}
        />
        {React.cloneElement(this.props.children, {
          onClick: () => this.fileInput.click(),
        })}
      </div>
    );
  }
}

FileInput.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
};
